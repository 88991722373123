<template>
  <AntRow>
    <AntCol :span="24">
      <AntRow justify="end">
        <AntButton
          type="primary"
          html-type="submit"
          @click="() => onToggleDrawer('createUserAgent')"
        >
          Create User Agent
        </AntButton>
      </AntRow>
      <!-- <p><b>searchState:</b> {{ searchState }}</p> -->
      <AntRow :gutter="16" class="search-row">
        <AntCol :span="6">
          <AntSelect
            placeholder="Source"
            v-model:value="searchState.source"
            :options="sourceOptions"
            class="search-input"
            showSearch
            allowClear
            filterOption
            :loading="!sourceOptions.length"
            :disabled="!sourceOptions.length"
          />
        </AntCol>

        <AntCol :span="4">
          <AntButton
            type="primary"
            html-type="submit"
            @click="onSearch"
          >
            Search
          </AntButton>
        </AntCol>
      </AntRow>

      <AntTable
        :columns="columns"
        :dataSource="displayedDataSource"
        :scroll="{ y: 'calc(100vh - 166px)' }"
        :pagination="{
          pageSize: 50,
          pageSizeOptions: ['50', '100', '300'],
          showSizeChanger: true,
        }"
        bordered
      >
        <template #userLevels="{ text }">
          <AntTag v-for="userLevel in text" :key="userLevel">{{ PERMISSION_NAME[userLevel] }}</AntTag>
        </template>
        <template #operation="{ record }">
          <a @click="onToggleDrawer('editUserAgent', record)">Edit</a>
        </template>
      </AntTable>

    </AntCol>
  </AntRow>

  <AntDrawer
    :title="drawerState.title"
    :width="720"
    :visible="drawerState.visible"
    :bodyStyle="{ paddingBottom: '80px' }"
    @close="e => onToggleDrawer()"
  >
    <!-- <p><b>drawerState.record:</b> {{ drawerState.record }}</p> -->
    <FormTemplate
      v-if="drawerState.visible"
      :formState="drawerState.record"
      :formFields="formFields"
      :onSubmit="onSubmit"
    />
    <AntAlert
      v-if="!!drawerState.alertMessage"
      class="warning-message"
      :type="drawerState.alertType"
      :message="drawerState.alertMessage"
      showIcon
    />
  </AntDrawer>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  onBeforeMount,
} from 'vue'

import {
  Row as AntRow,
  Col as AntCol,
  Tag as AntTag,
  Table as AntTable,
  Button as AntButton,
  Select as AntSelect,
  Drawer as AntDrawer,
  Alert as AntAlert,
  message as antMessage,
} from 'ant-design-vue'

import FormTemplate from '@/components/AgentManagement/FormTemplate'

import {
  PERMISSION_NAME,
  USER_LEVEL_OPTIONS,
} from '@/constants/testerPortal'

import {
  getSourceLines,
  batchWriteSourceLines,
  batchDeleteSourceLines,
  getSources,
  getAgentsBySource,
} from '@/api/testerportal'

export default {
  name: 'UserAgentList',
  components: {
    AntRow,
    AntCol,
    AntTag,
    AntTable,
    AntButton,
    AntSelect,
    AntDrawer,
    AntAlert,
    FormTemplate,
  },
  setup() {
    const searchState = reactive({})

    const columns = [
      {
        title: 'Source',
        dataIndex: 'source',
        width: '12.5%',
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'filterRender',
        },
      },
      {
        title: 'Line Type',
        dataIndex: 'line',
        width: '12.5%',
        customRender: ({ text }) => `${text} Line`,
      },
      {
        title: 'Agent ID',
        dataIndex: 'agentId',
        width: '15%',
      },
      {
        title: 'Prefix',
        dataIndex: 'prefix',
        width: '10%',
      },
      {
        title: 'Cert',
        dataIndex: 'cert',
        width: '20%',
      },
      {
        title: 'User Levels',
        dataIndex: 'userLevels',
        width: '20%',
        slots: {
          customRender: 'userLevels',
        },
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        width: '10%',
        slots: {
          customRender: 'operation',
        },
      },
    ]

    const dataSource = ref()
    const displayedDataSource = ref()

    const sourceOptions = ref([])
    const agentIdOptions = ref([])
    const agentPrefixMap = reactive({})

    const setDataSource = async () => {
      const { data: allAgentsData } = await getSourceLines()

      dataSource.value = allAgentsData.reduce((acc, { userLevel, sourceLineId, ...agentData }) => {
        const idxInAcc = acc.findIndex(d => d.key === sourceLineId)
        if (idxInAcc > -1) {
          acc[idxInAcc].userLevels.push(userLevel)
        } else {
          acc.push({
            key: sourceLineId,
            ...agentData,
            userLevels: [userLevel],
          })
        }
        return acc
      }, [])

      displayedDataSource.value = dataSource.value
    }

    onBeforeMount(async () => {
      setDataSource()
      const { data } = await getSources()
      sourceOptions.value = data.map(d => ({ label: d, value: d }))
    })

    const onSearch = () => {
      displayedDataSource.value = dataSource.value.filter((d) => {
        let matched = true
        if (searchState.source) matched = matched && d.source === searchState.source
        return matched
      })
    }

    const drawerState = reactive({ record: {} }) // default record = {} is for formFields defaultValue

    const setAgentIdOptionsAndPrefix = async (source) => {
      agentIdOptions.value = []
      const { data } = await getAgentsBySource(source)
      agentIdOptions.value = Object.keys(data).map(d => ({ label: d, value: d }))
      Object.assign(agentPrefixMap, data)
    }

    const formFields = computed(() => ([
      {
        id: 'source',
        label: 'Source',
        // defaultValue: drawerState.record.source,
        element: 'select',
        options: sourceOptions.value,
        onChange: async (value) => {
          drawerState.record.source = value
          delete drawerState.record.agentId
          delete drawerState.record.prefix
          await setAgentIdOptionsAndPrefix(value)
        },
        loading: drawerState.action === 'editUserAgent' ? false : !sourceOptions.value.length,
        disabled: drawerState.action === 'editUserAgent' ? true : !sourceOptions.value.length,
        ...(drawerState.action === 'createUserAgent' && {
          rules: [
            {
              required: true,
              message: 'Required',
            },
          ],
        }),
      },
      {
        id: 'line',
        label: 'Line Type',
        // defaultValue: drawerState.record.line,
        element: 'input',
        suffix: 'Line',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
      {
        id: 'agentId',
        label: 'Agent ID',
        // defaultValue: drawerState.record.agentId,
        element: 'select',
        options: agentIdOptions.value,
        loading: !agentIdOptions.value.length,
        disabled: !agentIdOptions.value.length,
        showSearch: true,
        onChange: async (value) => {
          drawerState.record.agentId = value
          drawerState.record.prefix = agentPrefixMap[value]
        },
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
      {
        id: 'prefix',
        label: 'Prefix',
        // defaultValue: drawerState.record.prefix,
        element: 'input',
        loading: !agentIdOptions.value.length,
        disabled: true,
      },
      {
        id: 'cert',
        label: 'Cert',
        // defaultValue: drawerState.record.cert,
        element: 'input',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
      {
        id: 'userLevels',
        label: 'User Levels',
        // defaultValue: drawerState.record.userLevels,
        element: 'select',
        mode: 'multiple',
        allowClear: true,
        options: USER_LEVEL_OPTIONS,
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      },
    ]))

    const actionTitleMap = {
      createUserAgent: 'Create User Agent',
      editUserAgent: 'Edit User Agent',
    }

    const onToggleDrawer = async (action, record) => {
      drawerState.visible = !drawerState.visible
      if (action) {
        drawerState.action = action
        drawerState.title = actionTitleMap[action]
        drawerState.record = { ...record }

        if (action === 'editUserAgent') await setAgentIdOptionsAndPrefix(record.source)
      }
    }

    const onSubmit = async (formState) => {
      const onSuccess = () => {
        onToggleDrawer()
        displayedDataSource.value = dataSource.value
        delete searchState.source
      }

      const { userLevels: oldUserLevels } = dataSource.value.find(({ key }) => key === formState.key) || {}
      const { userLevels: newUserLevels, ...userAgentData } = formState

      let sourceLineId
      if (drawerState.action === 'editUserAgent') {
        sourceLineId = drawerState.record.key
      } else { // createUserAgent
        const lastSourceLineId = dataSource.value.filter(({ source }) => source === formState.source).map(({ key }) => key).sort().slice(-1)[0]
        const lastSourceLineNo = lastSourceLineId ? Number(lastSourceLineId.split('-')[1]) : 0
        sourceLineId = `${formState.source}-${lastSourceLineNo + 1}`
      }

      const batchWriteSourceLinesData = newUserLevels.map(userLevel => ({
        ...userAgentData,
        sourceLineId,
        userLevel,
      }))

      const writeMessageKey = `writeSourceLines-${Date.now()}`
      const { data: writeData, message: writeMessage } = await batchWriteSourceLines(batchWriteSourceLinesData)

      if (writeData) {
        const existedUserAgent = dataSource.value.find(d => d.key === sourceLineId)
        if (existedUserAgent) {
          Object.assign(existedUserAgent, formState)
        } else {
          dataSource.value = [...dataSource.value, { ...formState, key: sourceLineId }]
        }

        antMessage.success({
          key: writeMessageKey,
          content: `Create / update user agent [${formState.agentId}] successfully`,
        })
        if (drawerState.action === 'createUserAgent') onSuccess()
      } else {
        antMessage.error({
          key: writeMessageKey,
          duration: 5,
          content: `Failed to create / update user agent [${formState.agentId}]: ${writeMessage}`,
        })
      }

      if (drawerState.action === 'editUserAgent') {
        const deleteMessageKey = `deleteSourceLines-${Date.now()}`
        const toDeleteUserLevels = oldUserLevels.filter(userLevel => !newUserLevels.includes(userLevel))
        console.log('oldUserLevels', oldUserLevels, 'newUserLevels', newUserLevels)
        console.log('toDeleteUserLevels', toDeleteUserLevels)

        if (toDeleteUserLevels.length) {
          const toDeleteUserLevelsText = toDeleteUserLevels.map(i => PERMISSION_NAME[i]).join(' / ')
          const batchDeleteSourceLinesData = toDeleteUserLevels.map(userLevel => ({ sourceLineId, userLevel }))
          console.log('batchDeleteSourceLinesData', batchDeleteSourceLinesData)

          const { data: deleteData, message: deleteMessage } = await batchDeleteSourceLines(batchDeleteSourceLinesData)
          console.log('batchDeleteSourceLines data', deleteData)
          if (deleteData) {
            Object.assign(dataSource.value.find(d => d.key === sourceLineId), formState)

            antMessage.success({
              key: deleteMessageKey,
              content: `Delete user agent [${formState.agentId}] under ${toDeleteUserLevelsText} successfully`,
            })
            onSuccess()
          } else {
            antMessage.error({
              key: deleteMessageKey,
              duration: 5,
              content: `Failed to delete user agent [${formState.agentId}] under ${toDeleteUserLevelsText}: ${deleteMessage}`,
            })
          }
        } else {
          onSuccess()
        }
      }
    }

    return {
      dataSource,
      displayedDataSource,
      columns,
      formFields,
      searchState,
      sourceOptions,
      onSearch,
      drawerState,
      onToggleDrawer,
      onSubmit,
      PERMISSION_NAME,
    }
  },
}
</script>

<style scoped>
.search-row {
  margin-bottom: 28px;
}
.search-input {
  width: 100%;
}
</style>
